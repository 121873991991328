import Header from 'component/global/Header/Header';
import React, { useEffect } from 'react';
import useResponsive from 'hooks/useResponsive';
import { getUserBookingById } from 'providers/booking';
import LoadingSpinner from 'component/LoadingSpinner';

import { useParams, useNavigate } from 'react-router-dom';
import Button from 'component/Button';
import Footer from 'component/global/Footer/Footer';
import Notification from 'component/Notification';
import { useTranslation } from 'react-i18next';
import * as style from './BookingStyle';

const Booking: React.FC = () => {
  const { t } = useTranslation();
  const screenSize = useResponsive();
  const navigate = useNavigate();
  const randomKeyString = Math.random().toString(36).substring(7);
  const [error, setError] = React.useState<string>('');
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { idBooking } = useParams<{ idBooking: string }>();
  const [bookingDetails, setBookingDetails] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(true);
  const isStrictMode = localStorage.getItem('silkke_single_booking') === 'true';

  useEffect(() => {
    document.title = t('title.yourBooking');
    if (idBooking) {
      getUserBookingById(idBooking)
        .then((res) => {
          setBookingDetails(res.data.booking);
          console.log(res.data.booking);
          if (new URLSearchParams(window.location.search).get('fastpass')) {
            localStorage.removeItem('token');
          }
          setLoading(false);
        })
        .catch((err) => setError(err.response.data.message));
    }
    // Nettoyage du localStorage lors de la navigation
    return () => {
      localStorage.removeItem('silkke_single_booking');
    };
  }, []);

  const downloadImage = (qrcode: string) => () => {
    const link = document.createElement('a');
    link.href = `${qrcode}`;
    link.download = 'qrcode.jpg';
    link.click();
  };
  return (
    <style.Container>
      <Header
        title="Book"
        subTitle="Capsule"
        desktopLinks={
          !new URLSearchParams(window.location.search).get('fastpass')
        }
      />
      {loading ? (
        <LoadingSpinner shouldBeCenter />
      ) : (
        <>
          <style.Booking>
            {isStrictMode && (
              <style.AddBookingLink size={screenSize}>
                <Button
                  onClick={() => navigate('/booking/new')}
                  width="100%"
                  disabled={false}
                  style={{
                    fontSize: screenSize?.isMobile ? '12px' : '14px',
                    padding: '6px 12px',
                    minHeight: '36px',
                    height: 'auto',
                    marginBottom: '5px',
                  }}
                >
                  {t('booking.addNew', { defaultValue: 'Ajouter une réservation' })}
                </Button>
              </style.AddBookingLink>
            )}

            <style.BookingTitle size={screenSize}>
              {bookingDetails.upcoming
                ? t('booking.confirmed')
                : t('booking.passed')}
            </style.BookingTitle>

            {bookingDetails.upcoming && (
              <>
                <style.BookingQrCode
                  size={screenSize}
                  src={bookingDetails.qrcode}
                />

                <style.BookingInfo size={screenSize}>
                  {t('booking.showQrCode', {
                    date: new Date(
                      bookingDetails.dateBooking,
                    ).toLocaleDateString(),
                  })}
                </style.BookingInfo>

                <style.BookingInfo size={screenSize}>
                  {bookingDetails.capsule_location.libel}
                </style.BookingInfo>

                <style.BookingInfo size={screenSize}>
                  {`${bookingDetails.capsule_location.location.address}, ${bookingDetails.capsule_location.location.zipCode} ${bookingDetails.capsule_location.location.city}`}
                </style.BookingInfo>

                {bookingDetails.capsule_location.company?.logo && (
                  <style.CompanyLogo
                    size={screenSize}
                    src={bookingDetails.capsule_location.company.logo}
                    alt="Company Logo"
                  />
                )}
                {bookingDetails.isSponsored && (
                  <style.SponsorSection>
                    <style.SponsorLabel>
                      {t('booking.sponsoredBy')}
                    </style.SponsorLabel>
                    {bookingDetails.transaction.coupon?.company?.logo ? (
                      <style.CompanyLogoModal
                        src={bookingDetails.transaction.coupon.company.logo}
                        alt={bookingDetails.transaction.coupon.company.name}
                      />
                    ) : (
                      <style.CompanyNameModal>
                        {bookingDetails.transaction.coupon?.company?.name}
                      </style.CompanyNameModal>
                    )}
                  </style.SponsorSection>
                )}
                <style.ButtonContainer size={screenSize}>
                  <Button
                    onClick={() => setIsModalOpen(true)}
                    width={screenSize?.isDesktop ? '200px' : '45%'}
                    disabled={false}
                    style={{
                      fontSize: screenSize?.isMobile ? '14px' : '16px',
                      padding: '8px 16px',
                      minHeight: '40px',
                      height: 'auto',
                    }}
                  >
                    {t('booking.details')}
                  </Button>

                  <Button
                    onClick={downloadImage(bookingDetails.qrcode)}
                    width={screenSize?.isDesktop ? '200px' : '45%'}
                    disabled={false}
                    style={{
                      fontSize: screenSize?.isMobile ? '14px' : '16px',
                      minHeight: '40px',
                      height: 'auto',
                    }}
                  >
                    {t('booking.download')}
                  </Button>
                </style.ButtonContainer>

                {isModalOpen && (
                  <style.ModalOverlay onClick={() => setIsModalOpen(false)}>
                    <style.ModalContent onClick={(e) => e.stopPropagation()}>
                      <style.ModalHeader>
                        <style.ModalTitle>
                          {t('booking.paymentDetails')}
                        </style.ModalTitle>
                        <style.CloseButton
                          onClick={() => setIsModalOpen(false)}
                        >
                          ×
                        </style.CloseButton>
                      </style.ModalHeader>
                      <style.ModalBody>
                        <style.ModalInfo>
                          <strong>{t('booking.paymentDate')}</strong>
                          {': '}
                          {new Date(
                            bookingDetails.dateCreation,
                          ).toLocaleString()}
                        </style.ModalInfo>
                        {(bookingDetails.isSponsored
                          || bookingDetails.transaction.coupon) && (
                          <style.ModalInfo>
                            <strong>{t('booking.initialAmount')}</strong>
                            {': '}
                            {`${bookingDetails.transaction.price} ${bookingDetails.currency}`}
                          </style.ModalInfo>
                        )}
                        <style.ModalInfo>
                          <strong>{t('booking.amountPaid')}</strong>
                          {': '}
                          {`${bookingDetails.amountPaid} ${bookingDetails.currency}`}
                        </style.ModalInfo>
                        {bookingDetails.transaction.paymentReference && (
                          <style.ModalInfo>
                            <strong>{t('booking.paymentReference')}</strong>
                            {': '}
                            {bookingDetails.transaction.paymentReference}
                          </style.ModalInfo>
                        )}
                      </style.ModalBody>
                    </style.ModalContent>
                  </style.ModalOverlay>
                )}
              </>
            )}
          </style.Booking>
          <Footer title={t('booking.download.step')} step={4} totalStep={4} />
        </>
      )}
      {error && (
        <Notification key={randomKeyString} message={error} type="error" />
      )}
    </style.Container>
  );
};

export default Booking;
