import COLORS from 'color';
import React from 'react';
import styled from 'styled-components';

import { ButtonProps } from '../type/buttonProps';

const Btn = styled.button`
  background: ${COLORS.BLUE_SILKKE};
  color: white;
  font-size: 1.2rem;
  display: block;
  margin: 0 auto;
  border: none;
  text-align: center;
  cursor: pointer;
  font-family: "Urbanist", sans-serif;
  font-weight: 400;
  border-radius: 8px;
  text-transform: uppercase;
  &:hover {
    background: ${COLORS.GAINSBORO};
    color: ${COLORS.BLUE_SILKKE};
    border: 1px solid ${COLORS.BLUE_SILKKE};
  }
  transition: background 0.8s ease;
`;
const Button: React.FC<ButtonProps> = ({
  disabled,
  children,
  height = '2rem',
  width = '100%',
  onClick,
  style,
}) => (
  <Btn
    type="submit"
    style={{ ...style, height, width }}
    onClick={() => onClick && onClick()}
    disabled={disabled}
  >
    {children}
  </Btn>
);

export default Button;
