import COLORS from 'color';
import styled, { keyframes } from 'styled-components';
import { ComponentProps } from 'type/ComponentProps';
import { ScreenSize } from 'type/ScreenSize';

export const Container = styled.div<{ size?: ScreenSize }>`
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.GAINSBORO};
  padding: ${({ size }) => (size?.isMobile ? '1rem' : '2rem')};
`;

export const SuccessCard = styled.div<{ size?: ScreenSize }>`
  background: white;
  padding: ${({ size }) => (size?.isMobile ? '1.5rem' : '3rem')};
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  width: 90%;
`;

export const Title = styled.h1<{ size?: ScreenSize }>`
  color: ${COLORS.BLUE_SILKKE};
  font-size: ${({ size }) => (size?.isMobile ? '1.5rem' : '2rem')};
  font-weight: 700;
  margin-bottom: 1.5rem;
  font-family: "Urbanist", sans-serif;
`;

export const Message = styled.p<{ size?: ScreenSize }>`
  color: ${COLORS.GREY};
  font-size: ${({ size }) => (size?.isMobile ? '1rem' : '1.2rem')};
  margin-bottom: 2rem;
  line-height: 1.5;
  font-family: "Urbanist", sans-serif;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const dotAnimation = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

export const CountdownWrapper = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CountDown = styled.div<{ size?: ScreenSize }>`
  font-size: ${({ size }) => (size?.isMobile ? '2.5rem' : '3rem')};
  color: ${COLORS.BLUE_SILKKE};
  font-weight: 700;
  font-family: "Urbanist", sans-serif;
  position: relative;
  z-index: 2;
`;

export const Circle = styled.div<{ progress: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 4px solid ${COLORS.GAINSBORO};
  border-top: 4px solid ${COLORS.BLUE_SILKKE};
  border-radius: 50%;
  animation: ${rotate} 1s linear infinite;
  transform: rotate(${({ progress }) => progress * 360}deg);
`;

export const LoadingDots = styled.span`
  display: inline-block;
  
  &::after {
    content: '...';
    display: inline-block;
    animation: ${dotAnimation} 1.5s infinite;
    animation-fill-mode: both;
    margin-left: 4px;
  }
`;
