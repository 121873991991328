/*   */
import styled from 'styled-components';
import BackgroundLoginDesktop from 'assets/img/background_login_desktop.jpg';
import { Link } from 'react-router-dom';
import { ComponentProps } from 'type/ComponentProps';
import COLORS from 'color';

export const SilkkeLogo = styled.img<ComponentProps>`
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 auto !important;
`;

export const Background = styled.div<ComponentProps>`
  background: ${({ size }) => (size?.isDesktop
    ? `url(${BackgroundLoginDesktop})`
    : 'linear-gradient(180deg, #454ece 0%, #bb7cb7 100%)')};
  background-size: cover;
  min-height: 100%;
  width: 100%;
  position: relative;
`;

export const Form = styled.form<ComponentProps>`
  background-color: ${COLORS.GAINSBORO};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${({ size }) => (size?.isMobile || size?.isTablet ? '85%' : '25%')};
  border-radius: 10px;
`;

export const FormInput = styled.input<ComponentProps>`
  background: rgba(255, 255, 255, 0.07);
  border: 1px solid ${COLORS.GREY};
  height: 2.2rem;
  width: 70%;
  text-align: left;
  font-size: 1rem !important;
  display: block;
  margin: 0 auto;
  padding: 0 1rem;
  ::placeholder {
    color: rgba(201, 202, 202, 1);
  }
`;

export const FormGroupInput = styled.div<ComponentProps>`
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 2rem 20px;
`;

export const FormHeader = styled.div<ComponentProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10% 0 0;
  padding-bottom: ${({ size }) => (size?.isTablet ? '5%' : '0')};
  > * {
    margin: 1rem 0;
  }
`;

export const FormHeaderTitle = styled.h1<ComponentProps>`
  font-family: "Urbanist", sans-serif;
  font-weight: 300;
  text-align: center;
  font-size: 1.5rem;
  color: ${COLORS.GREY};
`;

export const FormError = styled.div<ComponentProps>`
  color: ${COLORS.RED_LIGHT};
  font-family: "Urbanist", sans-serif;
  text-align: center;
  margin-top: 5%;
`;

export const SubLink = styled(Link)<any>`
  font-family: "Urbanist", sans-serif;
  text-decoration: underline;
  color: #abacac;
  text-align: center;
  display: block;
  font-size: ${({ fontSize }) => fontSize || '0.8rem'};
`;
export const SubLinkButton = styled.button<any>`
  font-family: "Urbanist", sans-serif;
  text-decoration: underline;
  display: block;
  margin: 0 auto;
  border: none;
  cursor: pointer;
  color: #abacac;
  text-align: center;
  display: block;
  font-size: ${({ fontSize }) => fontSize || '0.8rem'};
`;

export const FormBottom = styled.div<ComponentProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: ${({ size }) => (size?.isTablet ? '8% auto' : size?.isDesktop ? '10% auto' : '5% auto')};
  margin-bottom: 7%;
`;
