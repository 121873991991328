import React from 'react';
import { useTranslation } from 'react-i18next';
import useResponsive from 'hooks/useResponsive';
import FailedImage from 'assets/img/failed.png';
import * as style from './BookingLocationStyle';

const ListLocation: React.FC<any> = ({ locations }) => {
  const { t } = useTranslation();
  const screenSize = useResponsive();
  return locations.map((location: any) => {
    const dateFormatted = ` ${new Date(location.begin).toLocaleDateString(
      'fr-FR',
      { day: 'numeric', month: 'long', year: 'numeric' },
    )} ${t('booking.to')} ${new Date(location.end).toLocaleDateString('fr-FR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })}`;
    const address = location.location.address
      && location.location.zipCode
      && location.location.city
      ? `${location.location.address}`
      : '';

    const complementAdress = location.location.addressComplement;
    const city = ` ${location.location.zipCode} ${location.location.city}`;
    const price = location.price
      ? location.price === '0.00'
        ? `${t('booking.price.free')} ${location.libel}`
        : `${location.price} €`
      : '';
    const src = location.location.company
      ? location.location.company.logo
      : FailedImage;
    return (
      <style.BookingListItem
        key={location.idCapsuleLocation}
        size={screenSize}
        to={`/booking/new/${location.idCapsuleLocation}`}
      >
        <style.BookingListItemInfo size={screenSize}>
          <style.BookingListItemInfoPrice size={screenSize}>
            {location.libel}
          </style.BookingListItemInfoPrice>
          <style.BookingListItemInfoDate size={screenSize}>
            {dateFormatted}
          </style.BookingListItemInfoDate>
          <style.BookingListItemInfoAddress size={screenSize}>
            {address}
          </style.BookingListItemInfoAddress>
          {complementAdress && (
            <style.BookingListItemInfoAddress size={screenSize}>
              {complementAdress}
            </style.BookingListItemInfoAddress>
          )}
          <style.BookingListItemInfoAddress size={screenSize}>
            {city}
          </style.BookingListItemInfoAddress>
        </style.BookingListItemInfo>
      </style.BookingListItem>
    );
  });
};

export default ListLocation;
