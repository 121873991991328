/*   */
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ComponentProps } from 'type/ComponentProps';
import COLORS from 'color';

export const Container = styled.div<ComponentProps>`
  height: 100%;
  width: 100%;
  background: ${COLORS.GAINSBORO};
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const Booking = styled.div<ComponentProps>`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ size }) => (size?.isMobile ? '10px 0' : '15px 0')};
`;

export const BookingTitle = styled.h3<ComponentProps>`
  font-size: ${({ size }) => (size?.isMobile ? '1.25rem' : '1.5rem')};
  padding-top: ${({ size }) => (size?.isMobile ? '1%' : '0.5%')};
  font-family: "Urbanist", sans-serif;
  text-align: center;
  color: ${COLORS.BLUE_SILKKE};
  font-weight: 600;
  margin: 0.5rem 0;
`;

export const BookingInfo = styled.span<ComponentProps>`
  font-size: ${({ size }) => (size?.isMobile ? '1rem' : '1.2rem')};
  font-family: "Urbanist", sans-serif;
  display: block;
  text-align: center;
  margin: 0.3rem 0;
  color: ${COLORS.GREY};
  line-height: 1.2;
  padding: 0 ${({ size }) => (size?.isMobile ? '5%' : '2%')};

  &:first-of-type {
    font-weight: 600;
    color: ${COLORS.BLUE_SILKKE};
    font-size: ${({ size }) => (size?.isMobile ? '1.1rem' : '1.3rem')};
  }
`;

export const BookingQrCode = styled.img<ComponentProps>`
  text-align: center;
  display: block;
  max-width: ${({ size }) => (size?.isMobile ? '50vw' : '15vw')};
  max-height: ${({ size }) => (size?.isMobile ? '30vh' : '25vh')};
  margin: 0.5rem auto;
  object-fit: contain;
`;

export const CompanyLogo = styled.img<ComponentProps>`
  display: block;
  max-width: ${({ size }) => (size?.isMobile ? '30%' : '10%')};
  max-height: 10vh;
  margin: 0.5rem auto;
  height: auto;
  object-fit: contain;
`;

export const CompanyLogoModal = styled.img`
  height: 32px;
  object-fit: contain;
  vertical-align: middle;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: "Urbanist", sans-serif;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${COLORS.GAINSBORO};
`;

export const ModalTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;
  color: ${COLORS.BLUE_SILKKE};
  font-family: "Urbanist", sans-serif;
  font-weight: 600;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
  color: ${COLORS.GREY};
  &:hover {
    color: ${COLORS.BLUE_SILKKE};
  }
`;

export const ModalBody = styled.div`
  padding: 10px 0;
  font-family: "Urbanist", sans-serif;
`;

export const ModalInfo = styled.p`
  margin: 10px 0;
  font-size: 1rem;
  line-height: 1.5;
  color: ${COLORS.GREY};
  font-family: "Urbanist", sans-serif;

  strong {
    color: ${COLORS.BLUE_SILKKE};
    font-weight: 600;
  }
`;

export const SponsorSection = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid ${COLORS.GAINSBORO};
  text-align: center;
`;

export const SponsorLabel = styled.p`
  color: ${COLORS.GREY};
  font-size: 0.9rem;
  margin-bottom: 8px;
  font-family: "Urbanist", sans-serif;
`;

export const CompanyNameModal = styled.span`
  color: ${COLORS.BLUE_SILKKE};
  font-weight: 600;
  font-size: 1.1rem;
  font-family: "Urbanist", sans-serif;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: ${({ size }: ComponentProps) => (size?.isDesktop ? '400px' : '100%')};
  margin: 10px 0;
  gap: 20px;
`;

export const AddBookingLink = styled.div<ComponentProps>`
  width: ${({ size }: ComponentProps) => (size?.isDesktop ? '400px' : '90%')};
  margin: 0 auto;
  padding: 0;
  max-height: 50px;
`;
