import styled from 'styled-components';
import COLORS from 'color';
import { ComponentProps } from 'type/ComponentProps';

export const Container = styled.div`
  min-height: 100%;
  min-width: 100%;
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  background-color: ${COLORS.GAINSBORO};
`;

Container.displayName = 'Container';

export const Main = styled.main`
  max-height: 100%;
  overflow: scroll;
  > * {
    margin: 10% auto;
  }
`;

Container.displayName = 'MainSettings';

export const Form = styled.form<ComponentProps>`
  margin: 0 auto;
  min-width: ${({ size }) => (size?.isDesktop ? '50%' : '95%')};
  max-width: ${({ size }) => (size?.isDesktop ? '50%' : '95%')};
  min-height: 90%;
  max-height: 90%;
  grid-template-columns: ${({ size }) => (size?.isDesktop ? '1fr 1fr' : '1fr')};
`;

Container.displayName = 'FormSettings';

export const FormInput = styled.input<ComponentProps>`
  padding: 12px 16px;
  border: 1px solid rgba(217, 217, 217, 1);
  font-size: 1rem;
  font-weight: 500;
  outline: none;
  transition: all 0.2s ease-in-out;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  color: rgba(51, 51, 51, 1);
  background-color: ${COLORS.GAINSBORO};
  margin: 0 auto;
  min-width: 100%;
  max-width: 100%;
  border-radius: 4px;

  &:disabled {
    color: rgba(171, 172, 172, 1);
    background-color: ${COLORS.GAINSBORO};
    cursor: not-allowed;
  }
`;

FormInput.displayName = 'FormInput';

/* FormSelect must same design than forminput */

export const FormSelect = styled.select<ComponentProps>`
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 12px 16px;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  outline: none;
  transition: all 0.2s ease-in-out;
  display: block;
  color: rgba(171, 172, 172, 1);
  background-color: ${COLORS.GAINSBORO};
  margin: 0 auto;
  min-width: 100%;
  max-width: 100%;
`;

export const Title = styled.h1<ComponentProps>`
  font-size: 1.5rem;
  font-weight: BOLD;
  color: ${COLORS.BLUE_SILKKE};
  text-align: center;
`;
