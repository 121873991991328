import { request } from './request';

export const getUserBookings = async () => {
  const userBookings = await request(true).get('/booking/user');
  return userBookings;
};

export const getUserBookingById = async (id: string | number) => {
  const userBooking = await request(true).get(`/booking/${id}`);
  return userBooking;
};

export const createFreeBooking = async (
  idCapsuleLocation: number,
  dateBooking: string,
) => {
  const booking = await request(true).put('/booking/free', {
    dateBooking,
    idCapsuleLocation,
  });
  return booking;
};
export const createPaidBooking = async (
  idCapsuleLocation: number,
  dateBooking: string,
  coupon: string,
) => {
  const booking = await request(true).put('/booking/pay', {
    dateBooking,
    idCapsuleLocation,
    coupon,
  });
  return booking;
};

export const preparePaidBooking = async (
  idCapsuleLocation: number,
  dateBooking: string,
  coupon: string,
) => {
  const booking = await request(true).get('/booking/prepare-pay', {
    params: {
      dateBooking,
      idCapsuleLocation,
      coupon,
    },
  });
  return booking;
};

export const getSession = async (sessionId: string) => {
  const session = await request(true).get(`/booking/session/${sessionId}`);
  return session;
};
