import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useResponsive from 'hooks/useResponsive';
import { getSession } from 'providers/booking';
import * as style from './BookingPaymentSuccessStyle';

const BookingPaymentSuccess: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const screenSize = useResponsive();
  const [redirectPath, setRedirectPath] = useState<string | null>(null);
  const sessionId = new URLSearchParams(window.location.search).get('session_id');

  // Effet pour la redirection
  useEffect(() => {
    if (redirectPath) {
      navigate(redirectPath);
    }
  }, [redirectPath, navigate]);

  // Effet pour la logique principale
  useEffect(() => {
    let isActive = true;

    const init = async () => {
      if (!sessionId) {
        setRedirectPath('/');
        return;
      }

      try {
        const response = await getSession(sessionId);
        if (!isActive) return;

        const bookingId = response.data.idBooking;
        setTimeout(() => {
          if (isActive) {
            setRedirectPath(`/booking/${bookingId}`);
          }
        }, 3000);
      } catch (error) {
        if (!isActive) return;
        console.error('Erreur lors de la récupération du bookingId:', error);
        setRedirectPath('/');
      }
    };

    init();
    document.title = t('title.paymentSuccess');

    return () => {
      isActive = false;
    };
  }, [sessionId, t]);

  return (
    <style.Container size={screenSize}>
      <style.SuccessCard size={screenSize}>
        <style.Title size={screenSize}>
          {t('booking.payment.success.title')}
        </style.Title>
        <style.Message size={screenSize}>
          {t('booking.payment.success.message')}
        </style.Message>
        <style.Message size={screenSize}>
          {t('booking.payment.success.redirect')}
          <style.LoadingDots />
        </style.Message>
      </style.SuccessCard>
    </style.Container>
  );
};

export default BookingPaymentSuccess;
