import COLORS from 'color';
import styled from 'styled-components';
import { ComponentProps } from 'type/ComponentProps';

interface ScreenSize {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

interface FormInputProps {
  size: ScreenSize;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: ${COLORS.GAINSBORO};
  padding: 2rem 0;
`;

export const Form = styled.form<{ size: ScreenSize }>`
  width: 100%;
  max-width: ${({ size }) => (size.isMobile ? '100%' : '33rem')};
  display: flex;
  flex-direction: column;
  padding: ${({ size }) => (size.isMobile ? '0.5rem' : '1rem')};
  box-sizing: border-box;
`;

export const BookingSummaryCard = styled.div<{ size: ScreenSize }>`
  background: white;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const CouponCard = styled.div<{ size: ScreenSize }>`
  background: white;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Title = styled.h2`
  color: ${COLORS.BLUE_SILKKE};
  font-size: 2.5rem;
  font-weight: 800;
  text-align: center;
  font-family: "Urbanist", sans-serif;
  margin-bottom: 1rem;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const SummaryTitle = styled.h3<{ size: ScreenSize }>`
  color: ${COLORS.BLUE_SILKKE};
  font-size: ${({ size }) => (size.isMobile ? '1.5rem' : '1.8rem')};
  font-weight: 700;
  font-family: "Urbanist", sans-serif;
  margin-bottom: ${({ size }) => (size.isMobile ? '0.5rem' : '1rem')};
`;

export const SummaryText = styled.p<{ size: ScreenSize }>`
  color: ${COLORS.GREY};
  font-size: ${({ size }) => (size.isMobile ? '0.9rem' : '1.1rem')};
  line-height: 1.6;
  font-family: "Urbanist", sans-serif;
  margin: 0.25rem 0;
`;

export const PriceText = styled.div<{ size: ScreenSize }>`
  font-size: ${({ size }) => (size.isMobile ? '1.8rem' : '2rem')};
  font-weight: 700;
  color: ${COLORS.BLUE_SILKKE};
  text-align: center;
  margin: 0.5rem 0;
  font-family: "Urbanist", sans-serif;
`;

export const StrikethroughPrice = styled.span<{ size: ScreenSize }>`
  text-decoration: line-through;
  color: ${COLORS.GREY};
  font-size: ${({ size }) => (size.isMobile ? '1.4rem' : '1.6rem')};
  margin-right: 1rem;
`;

export const PriceArrow = styled.span`
  margin: 0 0.5rem;
  color: ${COLORS.GREY};
`;

export const DiscountedPrice = styled.span<{ size: ScreenSize }>`
  color: ${COLORS.BLUE_SILKKE};
  font-size: ${({ size }) => (size.isMobile ? '1.8rem' : '2rem')};
  font-weight: 700;
`;

export const Divider = styled.div`
  height: 1px;
  background: ${COLORS.GAINSBORO};
  margin: 0.5rem 0;
  width: 100%;
`;

export const FormGroupInput = styled.div<{ size: ScreenSize }>`
  display: flex;
  flex-direction: ${({ size }) => (size.isMobile ? 'column' : 'row')};
  gap: ${({ size }) => (size.isMobile ? '0.75rem' : '1rem')};
  width: 100%;
`;

export const CouponSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
`;

export const CompanyContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 16px;
  padding: 12px 16px;
  background: ${COLORS.BLUE_SILKKE}08;
  border: 1px solid ${COLORS.BLUE_SILKKE}20;
  border-radius: 12px;
  transition: all 0.2s ease;
  width: auto;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    background: ${COLORS.BLUE_SILKKE}10;
    transform: translateY(-1px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
`;

export const OfferedByText = styled.span`
  color: ${COLORS.GREY};
  font-size: 0.9rem;
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  display: flex;
  align-items: center;
`;

export const CompanyLogo = styled.img`
  height: 20px;
  object-fit: contain;
`;

export const CompanyName = styled.span`
  color: ${COLORS.BLUE_SILKKE};
  font-size: 0.95rem;
  font-weight: 600;
  font-family: "Urbanist", sans-serif;
`;

export const CouponChip = styled.div<{ size: ScreenSize }>`
  display: inline-flex;
  align-items: center;
  background: ${COLORS.BLUE_SILKKE}10;
  border: 1px solid ${COLORS.BLUE_SILKKE};
  border-radius: 20px;
  padding: ${({ size }) => (size.isMobile ? '0.4rem 0.8rem' : '0.5rem 1rem')};
  margin-top: 0.5rem;
  max-width: fit-content;
`;

export const CouponChipText = styled.span<{ size: ScreenSize }>`
  color: ${COLORS.BLUE_SILKKE};
  font-size: ${({ size }) => (size.isMobile ? '0.9rem' : '1rem')};
  font-weight: 600;
  font-family: "Urbanist", sans-serif;
  margin-right: 0.5rem;
`;

export const RemoveCouponButton = styled.button`
  background: none;
  border: none;
  color: ${COLORS.BLUE_SILKKE};
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 1.2rem;
  font-weight: 600;
  
  &:hover {
    opacity: 0.8;
  }
`;

export const VerifyCouponButton = styled.button<{ size: ScreenSize }>`
  background-color: ${COLORS.BLUE_SILKKE};
  color: white;
  border: none;
  border-radius: 12px;
  height: ${({ size }) => (size.isMobile ? '3rem' : '3.5rem')};
  padding: 0 1.5rem;
  cursor: pointer;
  font-weight: 600;
  font-family: "Urbanist", sans-serif;
  transition: all 0.2s ease;
  width: ${({ size }) => (size.isMobile ? '100%' : 'auto')};
  min-width: ${({ size }) => (size.isMobile ? 'auto' : '120px')};
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background-color: #e2e8f0;
    cursor: not-allowed;
  }
`;

export const FormButton = styled.button<{ size: ScreenSize }>`
  background: linear-gradient(135deg, ${COLORS.BLUE_SILKKE} 0%, ${COLORS.BLUE_SILKKE_DARK} 100%);
  color: white;
  font-size: ${({ size }) => (size.isMobile ? '1rem' : '1.2rem')};
  font-family: "Urbanist", sans-serif;
  font-weight: 600;
  padding: ${({ size }) => (size.isMobile ? '0.75rem' : '1rem')} 2rem;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
  height: ${({ size }) => (size.isMobile ? '3rem' : '3.5rem')};
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export const CouponQuestion = styled.p`
  font-size: 1.5rem;
  color: ${COLORS.BLUE_SILKKE};
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
  font-family: "Urbanist", sans-serif;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 2.5rem;
  margin: 1rem 0 2rem;
  justify-content: center;
  flex-wrap: wrap;
`;

export const RadioWrapper = styled.div`
  position: relative;
  min-width: 150px;
`;

export const RadioInput = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
`;

export const RadioLabel = styled.label<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem 1rem 3.5rem;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: "Urbanist", sans-serif;
  cursor: pointer;
  transition: all 0.3s ease;
  background: white;
  color: ${COLORS.BLUE_SILKKE};
  border: 2px solid
    ${(props) => (props.isSelected ? COLORS.BLUE_SILKKE : '#E0E0E0')};
  position: relative;
  user-select: none;

  &:before {
    content: "";
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid
      ${(props) => (props.isSelected ? COLORS.BLUE_SILKKE : '#E0E0E0')};
    background: white;
    transition: all 0.2s ease;
  }

  &:after {
    content: "";
    position: absolute;
    left: calc(1rem + 6px);
    top: 50%;
    transform: translateY(-50%) scale(${(props) => (props.isSelected ? 1 : 0)});
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${COLORS.BLUE_SILKKE};
    transition: all 0.2s ease;
  }

  &:hover {
    background: ${(props) => (props.isSelected ? 'white' : '#F8F9FF')};
    border-color: ${COLORS.BLUE_SILKKE};
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(69, 78, 206, 0.15);

    &:before {
      border-color: ${COLORS.BLUE_SILKKE};
    }
  }

  ${RadioInput}:focus + & {
    box-shadow: 0 0 0 3px rgba(69, 78, 206, 0.2);
  }

  @media (max-width: 768px) {
    padding: 0.8rem 1.2rem 0.8rem 3rem;
    font-size: 1rem;

    &:before {
      width: 20px;
      height: 20px;
    }

    &:after {
      width: 14px;
      height: 14px;
      left: calc(1rem + 5px);
    }
  }
`;

export const CouponInputContainer = styled.div<{ isVisible: boolean }>`
  max-height: ${(props) => (props.isVisible ? '100px' : '0')};
  opacity: ${(props) => (props.isVisible ? '1' : '0')};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const FI = styled.input<{ screenState: ScreenSize }>`
  background: #f8fafc;
  border: 2px solid transparent;
  height: ${({ screenState }) => (screenState.isMobile ? '3rem' : '3.5rem')};
  width: 100%;
  border-radius: 12px;
  padding: ${({ screenState }) => (screenState.isMobile ? '0 0' : '0 1rem')};
  font-size: ${({ screenState }) => (screenState.isMobile ? '0.9rem' : '1rem')};
  font-family: "Urbanist", sans-serif;
  color: ${COLORS.GREY};

  &:focus {
    outline: none;
    border-color: ${COLORS.BLUE_SILKKE};
    background: white;
  }

  ::placeholder {
    color: #a0aec0;
  }
`;
