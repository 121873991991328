import COLORS from 'color';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import { ComponentProps } from 'type/ComponentProps';
import { Link } from 'react-router-dom';

export const Container = styled.div<ComponentProps>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Main = styled.div<ComponentProps>`
  background: ${COLORS.GAINSBORO};
  flex: 1;
  padding: ${({ size }) => (size?.isMobile ? '5% 0' : '2% 0')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: visible;
`;

export const Title = styled.div<ComponentProps>`
  font-size: ${({ size }) => (size?.isMobile ? '1.5rem' : '2rem')};
  font-weight: bold;
  text-align: center;
  padding: ${({ size }) => (size?.isMobile ? '5% 0' : '1% 0')};
  margin-bottom: ${({ size }) => (size?.isMobile ? '0' : '10px')};
  color: ${COLORS.GREY};
  font-family: "Urbanist", sans-serif;
`;

export const CalendarComp = styled(Calendar)<ComponentProps>`
  margin: ${({ size }) => (size?.isMobile ? '5% auto' : '10px auto')};
  width: ${({ size }) => (size?.isMobile ? '90%' : '50%')};
  max-width: ${({ size }) => (size?.isMobile ? 'none' : '700px')};
  min-width: ${({ size }) => (size?.isMobile ? 'none' : '500px')};
  height: auto;
  box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  border-radius: 0.625rem;
  border: none;
  
  /* Styles pour le calendrier lui-même */
  .react-calendar__navigation {
    margin-bottom: ${({ size }) => (size?.isMobile ? '1rem' : '1.5rem')};
    height: ${({ size }) => (size?.isMobile ? 'auto' : '50px')};
  }

  .react-calendar__navigation button {
    font-size: ${({ size }) => (size?.isMobile ? '1rem' : '1.2rem')};
    padding: ${({ size }) => (size?.isMobile ? '0.5rem' : '0.8rem')};
  }

  .react-calendar__month-view__weekdays {
    font-size: ${({ size }) => (size?.isMobile ? '0.9rem' : '1.1rem')};
    font-weight: bold;
    text-transform: uppercase;
    color: ${COLORS.GREY};
    margin-bottom: ${({ size }) => (size?.isMobile ? '0.5rem' : '0.8rem')};
  }

  .react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr);
  }

  .react-calendar__tile {
    padding: ${({ size }) => (size?.isMobile ? '0.5rem' : '1rem')};
    font-size: ${({ size }) => (size?.isMobile ? '0.9rem' : '1.1rem')};
    height: ${({ size }) => (size?.isMobile ? 'auto' : '60px')};
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:enabled:hover,
    &:enabled:focus {
      background-color: ${COLORS.BLUE_SILKKE}20;
      border-radius: 0.5rem;
    }
  }

  .react-calendar__tile--active {
    background: ${COLORS.BLUE_SILKKE} !important;
    border-radius: 0.5rem;
    color: white;
  }

  .react-calendar__tile--disabled {
    background-color: ${COLORS.GAINSBORO}40;
    color: ${COLORS.GREY};
  }

  @media (min-width: 1200px) {
    width: 40%;
  }
`;

export const ButtonLink = styled(Link)<ComponentProps>`
  background: ${COLORS.BLUE_SILKKE};
  color: white;
  font-size: ${({ size }) => (size?.isMobile ? '1.2rem' : '1.4rem')};
  display: block;
  margin: ${({ size }) => (size?.isMobile ? '10% auto' : '20px auto')};
  border: none;
  width: ${({ size }) => (size?.isMobile ? '70%' : '30%')};
  max-width: ${({ size }) => (size?.isMobile ? 'none' : '400px')};
  min-width: ${({ size }) => (size?.isMobile ? 'none' : '200px')};
  padding: ${({ size }) => (size?.isMobile ? '1rem' : '1.2rem')};
  text-align: center;
  cursor: pointer;
  font-family: "Urbanist", sans-serif;
  font-weight: 400;
  border-radius: 0.5rem;
  &:hover {
    background: ${COLORS.BLUE_SILKKE_DARK};
  }
  transition: background 0.2s ease;
  text-decoration: none;
`;
